// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src514923377/src/HearRo_Web/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src514923377/src/HearRo_Web/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/codebuild/output/src514923377/src/HearRo_Web/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src514923377/src/HearRo_Web/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("/codebuild/output/src514923377/src/HearRo_Web/src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-works-js": () => import("/codebuild/output/src514923377/src/HearRo_Web/src/pages/works.js" /* webpackChunkName: "component---src-pages-works-js" */)
}

